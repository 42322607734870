$mainBackground: hsl(220, 13%, 18%);
$secondaryBackground: hsl(220, 13%, 28%);
$secondaryText: hsl(183, 20%, 70%);

body{
  background-color: $mainBackground
}



.loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: $mainBackground;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 5;
}

.sandwich {
  visibility: hidden;
  position: fixed;
  left: calc(100vw - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  z-index: 4;
  button {
    filter: invert(100%);
    font-size: 2rem;
    margin: 0;
  }
}

li .sandwichItems {
  text-decoration: none;
  color: white;
  width: 100%;
}

.section {
  background-color: $mainBackground;
  min-height: calc(100vh - 200px);
  padding-bottom: 100px;
  display: grid;
  align-content: center;
  gap: 3vh;
  align-items: center;
  justify-items: center;
  color: white;
  position: sticky;
  top: 0;
  
  @media (max-width: 1200px) {
    padding: 3%;
    position: relative;
    padding: 5vh;

    h1 {
      font-size: 1.5rem;
    }
    h2{
      font-size: 1rem;
    }
  }
}

.divider {
  position: absolute;
  align-self: start;
  top: 0;
  width: 100%;
  overflow: hidden;
  fill: hsl(220, 13%, 18%);
  transform: rotateZ(180deg) translateY(100%);

  @media (max-width: 1200px) {
    display: none;
  }
}

.imageContainer {
  width: 750px;
  height: 422px;
  display: flex;
  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 5px;
    @media (max-width: 1200px) {
      display: none;
    }
  }
  p {
    justify-self: center;
    align-self: center;
    width: 100%;
    text-align: center;
    font-style: italic;
    color: $secondaryText;
  }
}

footer {
  background-color: $mainBackground;
  color: $secondaryText;
  position: relative;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    height: 50%;
  }

  @media (max-width: 1200px) {
    img {
      display: none;
    }
  }
}

.error {
  h1 {
    margin: 0;
  }
  p {
    font-size: 2rem;
    margin-top: 0;
    color: $secondaryText;
  }
  a {
    font-size: 1.3rem;
  }
}

.accueil {
  grid-template-columns: auto 1fr;
  align-items: center;
  padding-bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0);
  position: unset;
  padding-bottom: 10vh;
  
  .portrait {
    height: 100vh;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: -5;
  }

  @media (max-width: 1200px) {
    padding-top: 0;
    grid-template-rows: 2fr 3fr;
    grid-template-columns: 1fr;
    gap: 50px;

    .portrait {
      height: 30vh;
      width: 30vh;
      border-radius: 50%;
      object-fit: cover;
      object-position: -40% 20%;
      background-color: white;
      position: relative;
    }

    h1 {
      font-size: 3.5rem !important;
    }

    .gridFiller {
      display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    h1 {
      margin: 0;
      font-size: 5rem;
    }

    h2 {
      margin: 0;
      font-size: 2rem;
      color: $secondaryText;
    }

    p {
      font-size: 1.1rem;
      margin-top: 4rem;

      a {
        color: cyan;
      }
    }

    span {
      transform: translateY(20vh);
      align-self: center;
    }

    @media (max-width: 1200px) {
      grid-template-rows: 2fr 3fr;
      grid-template-columns: 1fr;
      gap: 50px;

      div {
        align-self: start;
      }

      span {
        display: none;
      }
    }
  }
}

.experience {
  background-color: $secondaryBackground;

  .listContainer {
    margin: auto;
    margin-top: 50px;
  }

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }
}

.competences {
  background-color: $mainBackground;

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  .iconContainer {
    margin-top: 40px;
    display: flex;
    font-size: 3rem;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    span {
      width: 25%;
      display: flex;
      justify-content: center;
    }
  }
}

.creations {
  background-color: $secondaryBackground;
  margin-bottom: 10vh;
  padding-top: 10vh;

  .cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  @media (max-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    .cardContainer{
      grid-template-columns: 1fr;
    }
  }
}
